
import QuotationRequestFullDetails from "@/models/QuotationRequestFullDetails";
import { Vue, Component, Prop } from "vue-property-decorator";
import { IFile } from "@/models/File";
import { AdminService } from "@/api/AdminServiceApi";

@Component({})
export default class QuotationRequestModal extends Vue {
  @Prop({
    default: () => ({
      id: 0,
      name: "",
      mobilePhone: "",
      email: "",
      timeLimit: "",
      zipCode: "",
      category: "",
      otherInformation: "",
      questionsAndAnswers: [],
      files: []
    })
  })
  quotationRequest!: QuotationRequestFullDetails;
  isEditing: boolean = false;

  downloadFile(file: IFile): void {
    this.$emit("download-file", file);
  }

  close() {
    this.$emit("close");
  }

  enableEditing() {
    this.isEditing = !this.isEditing;
  }

  async closeQuotationRequest() {
    this.$swal
      .fire({
        titleText:
          "Ta bort offertförfrågan för " + this.quotationRequest.name + "?",
        confirmButtonText: "Ta bort",
        icon: "info",
        showCloseButton: true
      })
      .then(async result => {
        if (result.isConfirmed) {
          await AdminService.CloseQuotationRequest(this.quotationRequest.id)
            .then(() => {
              this.$swal.fire({
                titleText: "Offertförfrågan borttagen",
                confirmButtonText: "Ok",
                icon: "success"
              });
              this.$emit("remove", this.quotationRequest.id);
            })
            .catch(() => {
              this.$swal.fire({
                titleText: "Någonting gick fel",
                confirmButtonText: "Ok",
                icon: "error"
              });
            });
        }
      });
  }

  async updateZipCode() {
    this.$swal
      .fire({
        titleText:
          "Uppdatera postnummer för " +
          this.quotationRequest.name +
          "s offertförfrågan till " +
          this.quotationRequest.zipCode +
          "?",
        confirmButtonText: "Uppdatera",
        icon: "info",
        showCloseButton: true
      })
      .then(async result => {
        if (result.isConfirmed) {
          await AdminService.UpdateZipCode(
            this.quotationRequest.id,
            this.quotationRequest.zipCode
          )
            .then(() => {
              this.$swal.fire({
                titleText: "Postnummer uppdaterat",
                confirmButtonText: "Ok",
                icon: "success"
              });
              this.isEditing = false;
            })
            .catch(() => {
              this.$swal.fire({
                titleText: "Någonting gick fel",
                confirmButtonText: "Ok",
                icon: "error"
              });
            });
        }
      });
  }
}
