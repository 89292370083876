
import { Vue, Component } from "vue-property-decorator";
import QuotationRequestSlim, {
  QuotationRequestSlimDto
} from "@/models/QuotationRequestSlim";
import QuotationRequestFullDetails from "@/models/QuotationRequestFullDetails";
import { IFile } from "@/models/File";
import { saveAs } from "file-saver";
import QuotationRequestModal from "@/components/Dashboard/Admin/QuotationRequestModal.vue";
import admin from "@/store/modules/AdminModule";
import { AdminService } from "@/api/AdminServiceApi";

@Component({
  components: { QuotationRequestModal, Modal: QuotationRequestModal }
})
export default class DashboardQuotationRequests extends Vue {
  slimQuotationRequests: Array<QuotationRequestSlim> = [];

  details: QuotationRequestFullDetails[] | null[] = [];
  detailsAreLoading: Array<boolean> = [];

  limit: number = 10;
  offset: number = 0;
  currentPage: number = 1;
  pageSize: number = 0;

  async created() {
    await AdminService.GetQuotationRequests()
      .then(async response => {
        this.slimQuotationRequests = response.data.map(
          (quotationRequest: QuotationRequestSlimDto) =>
            new QuotationRequestSlim(quotationRequest)
        );
      })
      .catch(error => {});
  }

  get isAdmin() {
    return admin.isLoggedIn;
  }

  get pagesCount(): number {
    return Math.ceil(this.slimQuotationRequests.length / this.limit);
  }

  get limitDisplay(): number {
    const quotationRequestCount = this.slimQuotationRequests.length;
    if (this.limit > quotationRequestCount) {
      return quotationRequestCount;
    }
    return this.limit;
  }

  paginateNext() {
    this.resetState();
    this.currentPage++;
    this.offset += this.limit;
  }

  paginatePrevious() {
    this.resetState();
    this.currentPage--;
    this.offset -= this.limit;
  }

  resetState() {
    this.detailsAreLoading = [];
    this.details = [];
  }

  get quotationRequests(): Array<QuotationRequestSlim> {
    const offset = this.offset;
    const pageSize = this.pageSize;
    const page = this.currentPage;
    const size = page * pageSize;
    this.pageSize = this.limit;
    return this.slimQuotationRequests.slice(offset, size);
  }

  showingDetailedInformation(index: number): boolean {
    return this.details[index] != null && this.details[index]!.hidden == false;
  }

  async loadDetailedInformation(index: number, quotationRequestId: number) {
    if (this.details[index] == null) {
      Vue.set(this.detailsAreLoading, index, true);
      await AdminService.GetQuotationRequest(quotationRequestId)
        .then(async response => {
          const quotationRequestDetails = new QuotationRequestFullDetails(
            response.data
          );
          quotationRequestDetails.hidden = false;
          Vue.set(this.details, index, quotationRequestDetails);
          Vue.set(this.detailsAreLoading, index, false);
        })
        .catch(() => {});
    } else {
      this.details[index]!.hidden = !this.details[index]!.hidden;
    }
  }

  isLoading(index: number): boolean {
    return this.detailsAreLoading[index];
  }

  async downloadFile(file: IFile) {
    await AdminService.DownloadFile(file.id)
      .then(async response => {
        var blob = new Blob([response.data]);
        saveAs(blob, file.fileName);
      })
      .catch(error => {});
  }

  removeQuotationRequestFromList(quotationRequestId: number) {
    for (let i = 0; i < this.slimQuotationRequests.length; i++) {
      if (this.slimQuotationRequests[i].id == quotationRequestId) {
        this.slimQuotationRequests.splice(i, 1);
      }
    }

    for (let i = 0; i < this.details.length; i++) {
      if (
        this.details[i] != null &&
        this.details[i]!.id == quotationRequestId
      ) {
        this.details[i] = null;
      }
    }
  }
}
